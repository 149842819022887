import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | About Shine Supreme Car Wash and Detailing
			</title>
			<meta name={"description"} content={"At Shine Supreme, we believe that a car is more than just a mode of transportation – it's a reflection of its owner's personality and pride. "} />
			<meta property={"og:title"} content={"About Us | About Shine Supreme Car Wash and Detailing"} />
			<meta property={"og:description"} content={"At Shine Supreme, we believe that a car is more than just a mode of transportation – it's a reflection of its owner's personality and pride. "} />
			<meta property={"og:image"} content={"https://fronvest.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fronvest.live/img/download.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fronvest.live/img/download.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fronvest.live/img/download.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://fronvest.live/img/4.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					About us
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					At Shine Supreme, we believe that a car is more than just a mode of transportation – it's a reflection of its owner's personality and pride. Founded on the principles of meticulous workmanship, customer-centric service, and a passion for cars, our journey began with a simple yet powerful idea: to provide an unmatched car care experience.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				The Shine Supreme Philosophy
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Our team comprises car enthusiasts who understand the importance of detail. It's not just about cleaning – it's about restoring and preserving the beauty of your vehicle.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						{" "}Innovation in Care:
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						We constantly evolve our techniques and tools, ensuring we are at the forefront of car care technology, offering you the best solutions.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Environmentally Responsible:{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Understanding our responsibility towards the planet, we use eco-friendly products and methods, ensuring sustainability without compromising on quality.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						A Community Hub:
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Shine Supreme is more than a service – it's a community for car lovers. Here, stories are shared, advice is given, and friendships are made.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://fronvest.live/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Our Commitment to Excellence
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					At Shine Supreme, every car that rolls in is treated with the utmost care and respect, as if it were our own. We promise to deliver not just a service, but an experience that resonates with the love you have for your car.
					<br />
					<br />
					<br />
					<br />
					<Strong>
						Join the Shine Supreme Family - Where Your Car's Beauty is Our Passion
					</Strong>
				</Text>
			</Box>
		</Section>
		<Components.New />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});